:root {
    --background: white;
    --foreground: 222.2 47.4% 11.2%;

    --muted: gray;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --border-color: black;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: black;
    --primary-foreground: white;

    --secondary: #00f;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;

    --padding-small: 0.5rem;
    --padding-medium: 1rem;
    --padding-large: 2rem;
    --spacing-small: 0.5rem;
    --spacing-medium: 1rem;
    --spacing-large: 2rem;
}

.dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
}
@font-face {
  font-family: 'UncutSans';
  src: url('./fonts/UncutSans-Regular.woff') format('woff')
}

@font-face {
  font-family: BL;
  src: url('./fonts/BL/BLMelody-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: BL;
  src: url('./fonts/BL/BLMelody-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: BL;
  src: url('./fonts/BL/BLMelody-SemiBold.woff') format('woff');
  font-weight: 600;
}

@font-face {
  font-family: BL;
  src: url('./fonts/BL/BLMelody-Bold.woff') format('woff');
  font-weight: 900;
}
body {
  margin: 0;
  font-family: 'BL', 'UncutSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*>a:hover {
  color: var(--secondary) !important;
}

a {
  text-decoration: none;
}

a:visited {
  color: inherit;
}
ul {
  list-style-type: "→ ";
}