.App {
  padding-left: var(--padding-medium);
    padding-right: var(--padding-medium);
    max-width: 100%;
    height: 100vh;
}

.App-logo {
  height: 30vh;
}
.page-content {
  /* 
    Calculate the height of the page content by subtracting the height of the header
    headerHeight = 1.5rem + var(--padding-large) + var(--padding-medium) + 1rem
  */
  height: calc(100vh - 1.5rem - var(--padding-large) - var(--padding-medium) - 2rem);
}

@media screen and (max-width: 768px) {
  .page-content {
    /* 
      Calculate the height of the page content by subtracting the height of the header
      headerHeight = 1.5rem + var(--padding-large) + var(--padding-medium) + 3rem
    */
    height: calc(100vh - 1.5rem - var(--padding-large) - var(--padding-medium) - 4rem);
  }
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  }
  
        .stacked {
          display: flex;
          flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  .full-height {
    min-height: 100vh;
  }
  
  .socials {
    display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
  }
  
  .social-icon {
    margin: 0 0.5em;
    width: 2em;
    height: 2em;
  }