#logo-image {
    height: 2em;
    margin: 0 0.2em;
}

#name {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 1.5em;
    font-weight: 900;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--foreground);
}

#name:hover {
    color: var(--foreground) !important;
}
#header {
    position: sticky;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    padding: var(--padding-medium) var(--padding-medium) var(--padding-large);
    background-color: var(--background);
}

#navigation {
    display: flex;
    flex-wrap: wrap;
}

#navigation>a {
    color: var(--muted);
    margin: 0 var(--spacing-medium) 0 0;
    font-size: 1.2em;
}

