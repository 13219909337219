#main-container {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    height: 100%;
}

#main-container>*:first-child {
    width: 40%;
    border-right: 1px solid var(--border-color);
}

#main-container>*:nth-child(2) {
    width: 60%;
}

@media screen and (max-width: 768px) {
    #main-container {
        flex-direction: column;
        height: fit-content;
    }

    #main-container>*:first-child {
        flex-basis: 100%;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid var(--border-color);
    }

    #main-container>*:nth-child(2) {
        flex-basis: 100%;
        width: 100%;
    }
}
.section-title {
    border-bottom: 1px solid var(--border-color);
    text-transform: uppercase;
    margin-bottom: 0em;
}

.section>* {
    width: 95%;
    border-bottom: 1px solid lightgray;
    padding-top: 0.5em;
}

.section>*:last-child {
    border-bottom: none;
}
.text-links {
    display: flex;
    flex-wrap: wrap;
}

.text-links>a {
    color: gray;
    text-decoration: underline;
    padding: 0 var(--spacing-medium) 0 0;
}
.desktop {
    display: block;
}

.mobile {
    display: none;
}

@media screen and (max-width: 768px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}