.scrollable-container {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    height: 100%;
    max-width: 100%;
    padding: 0 1rem;
}

.border-bottom {
    border-bottom: 1px solid var(--border-color);
}

.border-top {
    border-top: 1px solid var(--border-color);
}

.border-left {
    border-left: 1px solid var(--border-color);
}

.border-right {
    border-right: 1px solid var(--border-color);
}

@media screen and (max-width: 768px) {
    .scrollable-container {
        padding: 0;
        width: 100%;
        overflow: visible;
    }
}